import { FormattedMessage, injectIntl } from "gatsby-plugin-react-intl"
import React from "react"
import styled from "styled-components"
import Heading from "../../components/Heading"
import ErrorBrowser from "../../images/icons/error-browser.svg"
import ErrorCloudflare from "../../images/icons/error-cloudflare.svg"
import ErrorHost from "../../images/icons/error-host.svg"
import Page from "../../layouts/Page"

const Container = styled.div`
  p {
    margin: 0 auto;
    text-align: center;
  }

  h4 {
    margin-bottom: 23px;
  }

  .wrapper {
    max-width: 750px;
    margin: 5rem auto;
    padding: 1rem;
  }

  .illustration-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .head {
    display: flex;
    text-align: left;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 5rem;
  }

  .head__ray {
    color: #6d7f7f;
    font-size: 1.5rem;
  }

  .illustration-list {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5rem;
  }

  .illustration-list__item {
    display: flex;
    flex-direction: column;
  }

  .illustration-list__item-image {
    width: auto;
    height: 60px;
    margin-bottom: 1rem;
  }

  .illustration-list__item-location {
    margin-bottom: 0.75rem;
  }

  .illustration-list__item-host {
    color: #6d7f7f;
  }

  .illustration-list__item-status--working {
    color: #00afbf;
  }

  .illustration-list__item-status--error {
    color: #e62b2b;
  }

  .description {
    max-width: 700px;
    font-size: 1.5rem;
    line-height: 1.5;
  }

  @media only screen and (max-width: 700px) {
    .head {
      display: block;
    }

    h4 {
      margin-bottom: 15px;
    }
  }
`

const date = new Date()
const formattedDate = date.toLocaleDateString()

const BadGatewayPage = () => (
  <Page hasHeader={false} hasFooter={false} pageId="maintenance/502">
    <Container>
      <div className="wrapper">
        <div className="head">
          <div className="head__status">
            <Heading type="h1">
              <FormattedMessage id="badgateway.errorcode" />
            </Heading>
            <Heading type="h3">
              <FormattedMessage id="badgateway.header" />
            </Heading>
          </div>
          <div className="head__ray">
            <Heading type="h4">Ray ID: ::RAY_ID:: {formattedDate}</Heading>
          </div>
        </div>

        <ul className="illustration-list">
          <li className="illustration-list__item">
            <img className="illustration-list__item-image" src={ErrorBrowser} />
            <p className="illustration-list__item-location">
              You <br />
              <span className="illustration-list__item-host">Browser</span>
            </p>
            <p className="illustration-list__item-status--working">Working</p>
          </li>
          <li className="illustration-list__item">
            <img
              className="illustration-list__item-image"
              src={ErrorCloudflare}
            />
            <p className="illustration-list__item-location">
              Hamburg <br />
              <span className="illustration-list__item-host">Cloudflare</span>
            </p>
            <p className="illustration-list__item-status--working">Working</p>
          </li>
          <li className="illustration-list__item">
            <img className="illustration-list__item-image" src={ErrorHost} />
            <p className="illustration-list__item-location">
              auth.bsdex.de <br />
              <span className="illustration-list__item-host">Host</span>
            </p>
            <p className="illustration-list__item-status--error">Error</p>
          </li>
        </ul>

        <p className="description">
          <FormattedMessage id="badgateway.description" />{" "}
          <a href="https://status.bsdex.de/" target="_blank">
            <FormattedMessage id="badgateway.descriptionStatus" />
          </a>
        </p>
      </div>
    </Container>
  </Page>
)

export default injectIntl(BadGatewayPage)
